.text-nama {
    font-family: 'Public Sans';
    color: #527A6F;
    font-weight: bolder;
}

.text-inbox{
    margin-right: 0px;
    text-align: justify;
    font-weight: lighter;

}

.text-bawah{
    font-family: 'Public Sans';
    color: #527A6F;
    font-size: x-large;
    font-style: italic;
    
}

.investor-responsive {
    display: none;
}

@media screen and (max-width: 480px) {
    .investor-responsive {
        display: flex;
        visibility: visible;
    }

    .investor-responsive .test-investor {
        padding-left: 25px;
        padding-right: 25px;
    }
}