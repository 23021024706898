.text-atas-contact {
    color: #B8B6B6;
    font-size: 11px;
}


.form-container {
    width: 100%;
}

.contact-responsive {
    display: none;
}

form {
    width: 75%;
  margin: 0 auto;
}