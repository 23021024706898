@media screen and (max-width: 480px){

    .investor {
        display: none;
    }

    .investor-responsive {
        display: flex;
    }

 }