.underline {
    text-decoration: underline;
    color: #EBE4DA;
}

.tengah-misi {
    display: flex;
}

.garis-stack {
    margin-bottom: 10px;
    width: 100%;
}

.text-tengah-responsive {
    visibility: hidden;
    width: 0;
}

.responsive-stack {
    width: 1px;
    visibility: hidden;
}

.responsive-kolom-mission {
    padding-left: 0px;
    padding-right: 0px;
    visibility: hidden;
}

.elemen-tengah .col-stack{
    width: 15%;
}

.text-tengah {
    color: #527A6F;
    font-family: 'Public Sans';
    text-align: justify;
    font-size: large    ;
}
.atas .gambar-atas {
    display: flex;
    border: none;
    border-radius: 20%;
}


.darken-image {
    filter: brightness(50%);
}

.tengah-misi .elemen-tengah{
    display: flex;
    padding-top: 50px;
    justify-content: start;
}

.arrow {
    width: 10%;
    display: inline;
    margin-left: 70px;
}

.topbar {
    text-decoration: #EBE4DA;
}

.peta {
    padding-top: 30px;
    padding-bottom: 100px;
    padding-left: 25px;
    position: relative;
    float: right;
    width: 200%;
}

.text-contact{
    padding-top: 50px;
    color: #B8B6B6;
    font-family: 'Public Sans';
    padding-bottom: 50px;
}
