.underline {
    text-decoration: underline;
    color: #EBE4DA;
}

.tengah {
    display: flex;
}

.garis-stack {
    width: 100%;
}

.text-tengah-responsive {
    visibility: hidden;
    width: 0;
}

.responsive-stack {
    width: 1px;
    visibility: hidden;
}


.elemen-tengah .col-stack{
    width: 15%;
}

.text-tengah-main {
    color: #527A6F;
    font-family: 'Public Sans';
    text-align: justify;
    font-size: large;
}

.atas .gambar-atas {
    display: flex;
    border: none;
    border-radius: 20%;
}

.responsive-kolom-bawah {
    display: none;
    visibility: hidden;
}

.darken-image {
    filter: brightness(50%);
}

.tengah .elemen-tengah{
    display: flex;
    padding-top: 50px;
    justify-content: start;
}

.arrow {
    width: 20px !important;
    display: flex !important;
    margin-top: -15px;
    
}

.topbar {
    text-decoration: #EBE4DA;
}

.peta {
    padding-top: 30px;
    padding-bottom: 100px;
    padding-left: 25px;
    position: relative;
    float: right;
    width: 200%;
}

.text-contact{
    padding-top: 50px;
    color: #B8B6B6;
    font-family: 'Public Sans';
    padding-bottom: 50px;
}

.responsive-kolom-value {
    display: none;
    visibility: hidden;
}
