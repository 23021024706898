.img-footer {
    display: inline;
    justify-content: center;
    width: 30%;
    max-width: 40%;
}

.img-footer-center {
    display: inline;
    justify-content: center;
    width: 40%;
}


.img-footer-center-2 {
    display: inline;
    justify-content: center;
    width: 40%;
}
.my-carousel-footer {
    display: flex;
    text-align: center;
}

.item-1 {
    padding-bottom: 50px;
    display: flex;
}
.my-carousel-mobile {
    visibility: hidden;
    display: none;
}

@media (max-width: 767px) {
    .my-carousel-footer{
        display: none;
        visibility: hidden;
    }

    .my-carousel-mobile {
        display: flex;
        visibility: visible;
    }

    
  }