.text-nama-pr {
    display: flex;
    font-family: 'Public Sans';
    color: #527A6F;
    font-weight: 600;
    padding-left: 25px;
    padding-top: 20px;
    padding-bottom: 15px;
}

.text-button {
    display: flex;
    justify-content: flex-start;
    padding-top: 5px;
    font-weight: 500;
}

.button-property {
    display: flex;
    justify-content: end;
    width: 100%;

}

.responsive-produk {
    display: none;
}