/* @media (max-width: 480px) {
    .tengah-team .elemen-tengah-team {
        display: none;
        visibility: hidden;
    }

    .responsive-kolom-team {
        display: flex;
        visibility: visible;
    }

    .row-2-tengah {
        padding-top: 25px;
    }

} */

@media (max-width: 480px) {
    .atas-team {
        display: none;
    }

    
    .atas {
        display: flex;
        visibility: visible;
    }

    .atas-team-responsive {
        display: flex;
        justify-content: center;
        visibility: visible;
        padding-left: 15px;
        padding-right: 15px;
    }

    

    .tengah-team {
        display: none;
        visibility: hidden;
    }

    .responsive-kolom-team {
        display: flex;
        padding-left: 15px;
        padding-right: 15px;
    }
}