.whatsapp-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 9999;
    background-color: #527A6F;
    color: #EBE4DA;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
.whatsapp-button:hover {
  background-color: #527A6F;
}

.gambar-row-1 {
  max-width: 100%;
}

.row-for-img-top{
  width: 50% ;
}

.judul-nesta {
  font-weight: 500;
  font-family: 'Public Sans';
  color: #527A6F;
}

.text-main {
  color: #527A6F;
  font-family: 'Public Sans';
  text-align: justify;
  font-weight: lighter;
  font-size: larger;
}

.text-content-2 {
  font-weight: bold;
  color: #527A6F;
  font-size: larger;
  font-family: 'Public Sans';
}

.text-content-3 {
  color: #527A6F;
  font-size: medium;
  font-family: 'Public Sans';
  
}

.text-content-4 {
  color: #527A6F;
  font-size: medium;
  font-family: 'Public Sans';
}

.gambar-kecil {
  max-width: 20%;
}

.text-kecil {
  display: inline;
  color: #527A6F;
  font-size: medium;
  text-align: justify;
  font-weight: bold;
  
}

.gmaps {
  max-width: 100%;
}


.nesta-loc {
  color: #527A6F;
  font-family: 'Public Sans';
}

.txt-upper-carousel {
  padding-top: 100px;
  padding-bottom: 100px;
  font-size: 29px;
  text-align: center;
  font-style: italic;
  color: #527A6F;
  font-family: 'Public Sans';
}

.main-menu-mobile {
  display: none;
  visibility: hidden;
}

.menu-mid-mobile {
  display: none;
  visibility: hidden;
}

@media (max-width: 767px) {
  .main-menu-mobile {
    display: flex;
    visibility: visible;
  }

  .menu-mid-mobile {
    display: flex;
    visibility: visible;
  }
  .text-main {
    background-color: black;
  }

  .nesta-resp{
    color: #527A6F;
    font-family: 'Public Sans';
  }

  .nesta-content {
  color: #527A6F;
  font-family: 'Public Sans';
  text-align: justify;
  }

  .gb-resp {
    max-width: 100%;
    padding: 50px;
  }

  .main-menu-desktop {
    visibility: hidden;
    display: none;
  }
  .menu-mid-desktop {
    visibility: hidden;
    display: none;
  }
}